import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import media from 'styled-media-query';
import { themeGet } from '@styled-system/theme-get';
import { breakpointsObject as bp } from '@firsttable/web-theme';
import {
  Box, BodyTitle, Text, Title,
} from '../../atoms';

const StyledBackground = styled(Box)`
  padding: 58px 64px;
  ${media.lessThan(bp.s)`
    border-top: 1px ${themeGet('colors.grayscale.300')} solid;
    border-bottom: 1px ${themeGet('colors.grayscale.300')} solid;
    margin: 20px 0;
    padding: 20px 0;
    background: none;
  `};
`;

const InfoBox = ({
  title, bodyTitle, bodyText, body, message, gradient,
}) => (
  <StyledBackground colors={gradient}>
    <Title size="h4" color="brand.blue">
      {title}
    </Title>
    <BodyTitle size="s">
      {bodyTitle}
    </BodyTitle>
    {bodyText && <Text>{bodyText}</Text>}
    {body}
    {message}
  </StyledBackground>
);

InfoBox.propTypes = {
  title: PropTypes.string,
  bodyTitle: PropTypes.string,
  bodyText: PropTypes.string,
  body: PropTypes.node,
  message: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  gradient: PropTypes.string,
};

InfoBox.defaultProps = {
  title: '',
  bodyTitle: '',
  bodyText: null,
  body: null,
  message: '',
  gradient: 'gradient.blue',
};

export default InfoBox;
