import React from 'react';
import dayjs from 'dayjs';
import Text from '../../atoms/Text';
import Link from '../../atoms/Link';

const BookingFooter = ({
  NavLinkComponent,
}: {
  NavLinkComponent: React.ReactNode;
}) => (
  <Text color="grayscale.600" fontSize="s" mb="30px" textAlign="center">
    © First Table 2014 - {dayjs().format('YYYY')}{' '}
    <Link
      NavComponent={NavLinkComponent}
      to="/privacy-policy/"
      color="brand.blue"
      underline
    >
      Privacy
    </Link>{' '}
    and{' '}
    <Link
      NavComponent={NavLinkComponent}
      to="/privacy-policy/"
      color="brand.blue"
      underline
    >
      Terms
    </Link>
  </Text>
);

export default BookingFooter;
