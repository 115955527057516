import React from 'react';
import PropTypes from 'prop-types';
import ContainerContent from '@firsttable/web-components/molecules/ContainerContent';
import RowHead from '@firsttable/web-components/molecules/RowHead';
import { Row, Col } from '@firsttable/web-components/atoms';
import ErrorDisplay from '@firsttable/web-components/molecules/ErrorDisplay';
import ThemeLayout from './layout';
import MainMenu from '../components/organisms/Navigation/Menu';
import Footer from '../components/organisms/Footer';
import NavLink from '../components/atoms/NavLink';

const LoadingLayout = ({ title, subTitle, showMenu, showFooter }) => (
  <ThemeLayout footer={showFooter && <Footer />}>
    {showMenu && <MainMenu />}
    <ContainerContent>
      {(title || subTitle) && <RowHead title={title} subTitle={subTitle} />}
      <Row>
        <Col>
          <ErrorDisplay NavLinkComponent={NavLink} />
        </Col>
      </Row>
    </ContainerContent>
  </ThemeLayout>
);

LoadingLayout.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  showMenu: PropTypes.bool,
  showFooter: PropTypes.bool,
};
LoadingLayout.defaultProps = {
  title: '',
  subTitle: '',
  showMenu: true,
  showFooter: true,
};

export default LoadingLayout;
