import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useQuery, useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import {
  Link,
  Text,
  Col,
  BodyTitle,
  Row,
} from '@firsttable/web-components/atoms';
import ContainerContent from '@firsttable/web-components/molecules/ContainerContent';
import RowHead from '@firsttable/web-components/molecules/RowHead';
import InfoBox from '@firsttable/web-components/molecules/InfoBox';
import PageLoading from '@firsttable/web-components/molecules/PageLoading';
import BookingFooter from '@firsttable/web-components/organisms/Footer/BookingFooter';
import {
  Table,
  TBody,
  Td,
  Th,
  THead,
  Tr,
} from '@firsttable/web-components/atoms/Table';
import { currencyFormat } from '@firsttable/functions';
import ThemeLayout from './layout';
import MainMenu from '../components/organisms/Navigation/Menu';
import { dataLayer, fbq } from '../helpers/events';
import ErrorLayout from './ErrorLayout';
import NavLinkComponent from '../components/atoms/NavLink';
import Logger from '../helpers/logger';

const logger = new Logger('Gift Voucher Confirmation');

const GET_VOUCHER_CONFIRMATION = gql`
  query GiftVoucher($id: Int) {
    voucher: GiftVoucher(id: $id) {
      id
      code
      title
      status
      email
      theme
      purchasedFor
      code
      fromName
      toName
      phoneNumber
      currency
      price
      value
      voucherConfirmation
      conversionData
    }
  }
`;

const SET_GA_TRACKING = gql`
  mutation updateProduct($id: Int, $reference: String) {
    updateProductPostConfirmation(
      id: $id
      reference: $reference
      sentToGoogleAnalytics: true
    ) {
      sentToGoogleAnalytics
    }
  }
`;

const ConfirmationContent = ({ voucher }) => {
  const [updateProductPostConfirmation] = useMutation(SET_GA_TRACKING);
  useEffect(() => {
    const { conversionData } = voucher;
    const convData = JSON.parse(conversionData);
    logger.debug('Voucher data', convData);
    dataLayer.push({
      google_tag_params: {
        local_id: 'Voucher',
        local_dealid: 'Voucher',
        local_pagetype: 'conversion',
        local_totalvalue: convData.Paid,
      },
      transactionId: voucher.id,
      transactionAffiliation: 'Voucher',
      transactionTotal: convData.Paid,
      transactionTax: convData.tax,
      transactionShipping: 0,
      transactionCurrency: voucher.currency,
      transactionProducts: [
        {
          sku: 'Voucher',
          name: 'First Table Voucher',
          category: 'Voucher',
          price: convData.Paid,
          quantity: 1,
        },
      ],
    });
    fbq('track', 'Purchase', {
      value: convData.Paid,
      currency: voucher.currency,
      content_name: 'Voucher',
      content_type: 'product',
      content_ids: ['Voucher'],
      num_item: 1,
    });
    // mark sent to google analytics
    updateProductPostConfirmation({ variables: { id: voucher.id } });
  }, []);
  return (
    <ContainerContent>
      <RowHead title="Purchase Complete" />
      <Row gap={60}>
        <Col width={[1, 1 / 2]} pr={['0', '30px']} order={[1, 0]}>
          <BodyTitle>Gift Voucher Purchase Complete</BodyTitle>
          <Text>Congratulations! Your gift voucher purchase is complete.</Text>
          <Text>
            {/* todo: create endpoint for download */}
            <Link
              href={`${process.env.GATSBY_SERVER_ENDPOINT}GiftVoucherController/${voucher.id}/downloadVoucher`}
              color="brand.red"
              underline
              download
            >
              Download Gift Voucher
            </Link>
          </Text>
          <Table>
            <THead>
              <Tr>
                <Th width={['100%', '200px']}>Voucher status</Th>
                <Th>Reference</Th>
                <Th>Value</Th>
                {voucher.price < voucher.value && <Th>Purchase price</Th>}
              </Tr>
            </THead>
            <TBody>
              <Tr>
                <Td>
                  <Text display={[null, 'none']} as="span" fontSize="s">
                    Your transaction has been:
                  </Text>{' '}
                  {voucher.status}
                </Td>
                <Td>
                  <Text display={[null, 'none']} as="span" fontSize="s">
                    Your receipt number is:
                  </Text>{' '}
                  {voucher.code}
                </Td>
                <Td>
                  <Text display={[null, 'none']} as="span" fontSize="s">
                    Total amount:
                  </Text>{' '}
                  {currencyFormat(voucher.value, voucher.currency)}
                </Td>
                {voucher.price < voucher.value && (
                  <Td>
                    <Text display={[null, 'none']} as="span" fontSize="s">
                      Purchase price:
                    </Text>{' '}
                    {currencyFormat(voucher.price, voucher.currency)}
                  </Td>
                )}
              </Tr>
            </TBody>
          </Table>
        </Col>
        <Col pl={['0', '30px']}>
          <InfoBox
            title="Gift Voucher"
            bodyTitle={`Purchase: ${currencyFormat(
              voucher.value,
              voucher.currency,
            )} Gift Voucher`}
            body={
              voucher.purchasedFor === 'Someone' ? (
                "We'll deliver the voucher to your email for you to print and give to someone special. They'll be able to redeem the value for credit on First Table."
              ) : (
                <>
                  The {currencyFormat(voucher.value, voucher.currency)} credit
                  has been added to your First Table login to be redeemed as{' '}
                  booking credit on our website. You can check your account
                  balance in your{' '}
                  <Link
                    NavComponent={NavLinkComponent}
                    to="/profile/account-balance/"
                    underline
                    color="brand.red"
                  >
                    profile settings
                  </Link>
                  .
                </>
              )
            }
          />
        </Col>
      </Row>
    </ContainerContent>
  );
};

ConfirmationContent.propTypes = {
  voucher: PropTypes.shape(),
};

const ConfirmationLayout = ({ confirmationId }) => {
  const { loading, error, data } = useQuery(GET_VOUCHER_CONFIRMATION, {
    variables: { id: confirmationId },
    fetchPolicy: 'network-only',
  });
  if (loading || !confirmationId) {
    return (
      <ThemeLayout>
        <Row>
          <Col pt="100px">
            <PageLoading />
          </Col>
        </Row>
      </ThemeLayout>
    );
  }
  if (error) return <ErrorLayout />;
  const { voucher } = data;
  return (
    <ThemeLayout footer={<BookingFooter NavLinkComponent={NavLinkComponent} />}>
      <MainMenu onlyLogo />
      <ConfirmationContent voucher={voucher} />
    </ThemeLayout>
  );
};

ConfirmationLayout.propTypes = {
  confirmationId: PropTypes.string,
};

export default ConfirmationLayout;
