import React from 'react';
import PropTypes from 'prop-types';
import { Field, withFormik } from 'formik';
import * as Yup from 'yup';
import gql from 'graphql-tag';
import { graphql as apolloGraphql } from '@apollo/client/react/hoc';
import { flowRight as compose } from 'lodash';
import FormField from '@firsttable/web-components/molecules/FormField/FormField';
import Box from '@firsttable/web-components/atoms/Box';
import Button from '@firsttable/web-components/atoms/Button';
import { errorMessage } from '@firsttable/functions';
import FormLayout from '@firsttable/web-components/organisms/Forms/Form';

const REDEEM_VOUCHER = gql`
  mutation redeemGiftVoucher($code: String!, $siteId: Int!) {
    redeemGiftVoucher(code: $code, siteId: $siteId) {
      id
    }
  }
`;

const RedeemForm = ({ isSubmitting, ...props }) => (
  <FormLayout
    data-testid="redeem__form"
    {...props}
    Action={
      <Box mt={['20px', '30px']} mb="20px">
        <Button size="l" kind="cta" type="submit" isLoading={isSubmitting}>
          Next
        </Button>
      </Box>
    }
  >
    <Field
      component={FormField}
      mb={['15px', '20px']}
      name="code"
      size="l"
      type="text"
      label="Gift Voucher code"
    />
  </FormLayout>
);

RedeemForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool,
  status: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
};

RedeemForm.defaultProps = {
  isSubmitting: false,
};

export default compose(
  apolloGraphql(REDEEM_VOUCHER, { name: 'redeemGiftVoucher' }),
  withFormik({
    mapPropsToValues: () => ({ code: '' }),
    validationSchema: () =>
      Yup.object().shape({
        code: Yup.string().required('Required'),
      }),
    handleSubmit: async (
      values,
      { setSubmitting, setStatus, resetForm, props: { redeemGiftVoucher } },
    ) => {
      try {
        const { error } = await redeemGiftVoucher({
          variables: { code: values.code, siteId: process.env.GATSBY_SITE_ID },
        });
        if (!error) {
          resetForm();
          setStatus({
            message: 'Congratulations! Your credit has been applied.',
            type: 'success',
          });
          return setSubmitting(false);
        }
        resetForm();
        setStatus({ message: errorMessage(error), type: 'danger' });
        return setSubmitting(false);
      } catch (e) {
        resetForm();
        setStatus({ message: errorMessage(e), type: 'danger' });
        return setSubmitting(false);
      }
    },
  }),
)(RedeemForm);
