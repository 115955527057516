import React, { useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { navigate, useStaticQuery, graphql } from 'gatsby';
import { Field, withFormik } from 'formik';
import * as Yup from 'yup';
import gql from 'graphql-tag';
import { graphql as apolloGraphql } from '@apollo/client/react/hoc';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import { flowRight as compose } from 'lodash';
import FormField from '@firsttable/web-components/molecules/FormField/FormField';
import InfoBox from '@firsttable/web-components/molecules/InfoBox';
import CardSection from '@firsttable/web-components/organisms/Forms/Fields/CardSection';
import {
  errorMessage,
  currencyFormat,
  hasPermission,
} from '@firsttable/functions';
import FormLayout from '@firsttable/web-components/organisms/Forms/Form';
import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import {
  Box,
  BodyTitle,
  Text,
  Row,
  Col,
  Button,
  Link,
} from '@firsttable/web-components/atoms';
import parseHtml from '../../../helpers/parseHtml';
import { dataLayer, fbq } from '../../../helpers/events';
import ModalContext from '../../../context/ModalContext';
import { withLocation } from '../../../hocs';

const CREATE_GIFT_VOUCHER = gql`
  mutation createGiftVoucher(
    $price: Float!
    $value: Float
    $slug: String
    $theme: String
    $email: String!
    $fromName: String
    $toName: String
    $stripeToken: String
    $purchasedFor: String
    $saveCard: Boolean
    $siteId: Int!
  ) {
    createGiftVoucher(
      price: $price
      value: $value
      slug: $slug
      theme: $theme
      email: $email
      fromName: $fromName
      toName: $toName
      stripeToken: $stripeToken
      purchasedFor: $purchasedFor
      saveCard: $saveCard
      siteId: $siteId
    ) {
      id
    }
  }
`;

const StyledConditions = styled(Box)`
  color: ${themeGet('colors.grayscale.600')};
`;

const themeOptions = [
  'Any Occasion',
  'Christmas',
  'Happy Holidays',
  "Mother's Day",
  "Father's Day",
  "Valentine's Day",
];

const PriceList = ({ giftVoucherPrices, hasDiscount, discount, currency }) => {
  const priceValues = [];
  giftVoucherPrices.forEach((price) => {
    if (!hasDiscount) {
      priceValues.push({
        value: price,
        label: currencyFormat(price, currency),
      });
    } else {
      const discountedPrice = price * (1 - discount);
      priceValues.push({
        value: price,
        label: `${currencyFormat(price, currency)} voucher for ${currencyFormat(
          discountedPrice,
          currency,
        )}`,
      });
    }
    return true;
  });
  return (
    <Field
      component={FormField}
      type="select"
      name="price"
      id="Price"
      label="Voucher value"
      placeholder="Choose a gift voucher value"
      mb={['15px', '30px']}
      options={priceValues}
    />
  );
};

PriceList.propTypes = {
  giftVoucherPrices: PropTypes.array.isRequired,
  hasDiscount: PropTypes.bool,
  discount: PropTypes.number,
  currency: PropTypes.string,
};

const AccordionContent = styled.div`
  ${({ scrollHeight, active }) =>
    !active
      ? css`
          height: 0;
        `
      : css`
          height: ${scrollHeight}px;
        `};
  overflow: hidden;
  transition: height 0.2s ease;
  margin-bottom: ${(props) =>
    props.active ? `${themeGet('space.m')(props)}px` : null};
`;

const GiftVoucherFormLayout = ({
  handleSubmit,
  isSubmitting,
  giftVoucherData: { content, ...gfdata },
  setFieldValue,
  siteConfig,
  termsAndConditions,
  userIsLoggedIn,
  values,
  ...props
}) => {
  const [active, setActiveState] = useState(true);
  const [scrollHeight, setScrollHeight] = useState('0');
  const contentElem = useRef(null);
  const { showModalFunc } = useContext(ModalContext);
  useEffect(() => {
    dataLayer.push({
      google_tag_params: {
        local_id: 'Voucher',
        local_dealid: 'Voucher',
        local_pagetype: 'conversionintent',
      },
    });
    fbq('track', 'AddToCart', {
      content_name: 'Voucher',
      content_ids: ['Voucher'],
      content_type: 'product',
    });
    setScrollHeight(contentElem.current.scrollHeight);
  }, []);

  useEffect(() => {
    setActiveState(values.purchasedFor === 'Someone');
  }, [values.purchasedFor]);
  return (
    <FormLayout
      handleSubmit={handleSubmit}
      data-testid="giftvoucher__form"
      {...props}
    >
      <Row gap={164}>
        <Col width={[1, 1 / 2]}>
          <PriceList {...gfdata} {...siteConfig} />
          <Field
            component={FormField}
            type="select"
            name="theme"
            id="Theme"
            label="Voucher theme"
            placeholder="Choose a voucher theme"
            mb={['15px', '30px']}
            options={themeOptions.map((v) => ({ value: v, label: v }))}
          />
          <Field
            component={FormField}
            type="email"
            name="email"
            id="Email"
            label="Your email address (we'll email you the voucher)"
            placeholder="Enter an email address"
            mb={['15px', '30px']}
          />
          {userIsLoggedIn && (
            <>
              <Text as="label" htmlFor="PurchasedFor" m="20px 0 0">
                Who are you purchasing for?
              </Text>
              <Text color="grayscale.600">
                Purchase a gift voucher for someone special or stock up on
                credit for yourself.
              </Text>
              <Field
                component={FormField}
                type="radio"
                name="purchasedFor"
                id="PurchasedFor"
                inline
                label="Who are you purchasing for?"
                mb={['15px', '30px']}
                options={[
                  {
                    inputValue: 'Someone',
                    inputLabel: 'Purchasing for someone',
                  },
                  { inputValue: 'Myself', inputLabel: 'Purchasing for myself' },
                ]}
              />
            </>
          )}
          <AccordionContent
            ref={contentElem}
            scrollHeight={scrollHeight}
            active={active}
          >
            <Field
              component={FormField}
              type="text"
              name="fromName"
              id="FromName"
              label="From name on voucher (optional)"
              placeholder="Enter a from name on the voucher"
              mb={['15px', '30px']}
            />
            <Field
              component={FormField}
              type="text"
              name="toName"
              id="ToName"
              label="Recipient's name on voucher (optional)"
              placeholder="Enter a recipient's name on the voucher"
              mb={['15px', '30px']}
            />
          </AccordionContent>
          <Field
            component={FormField}
            type="checkbox"
            name="terms"
            id="Terms"
            label="I accept the below conditions of purchase"
          />
        </Col>
        <Col width={[1, 1 / 2]} line>
          <InfoBox
            title="Gift Voucher"
            body={
              <>
                {!userIsLoggedIn && (
                  <Text>
                    You're not logged in.{` `}
                    <Link
                      onClick={() => showModalFunc('login')}
                      underline
                      color="brand.secondary"
                    >
                      Click here
                    </Link>{' '}
                    to login or feel free to continue as a guest.
                  </Text>
                )}
                <BodyTitle size="xs" as="h3">
                  How it works
                </BodyTitle>
                <ol>
                  <li>
                    Our gift vouchers are redeemable for First Table booking
                    credit (not redeemable at restaurants)
                  </li>
                  <li>
                    Purchase as a gift or redeem yourself to load up on credit
                  </li>
                  <li>The best part? They don't expire!</li>
                </ol>
                <br />
              </>
            }
            gradient="gradient.gold"
            message={
              <>
                {!hasPermission(['FREE_GIFT_VOUCHERS']) && (
                  <CardSection setFieldValue={setFieldValue} />
                )}
                <Button
                  type="submit"
                  size="l"
                  wide
                  kind="cta"
                  isLoading={isSubmitting}
                >
                  Purchase gift voucher
                </Button>
              </>
            }
          />
        </Col>
      </Row>
      <Row mt={['20px', '100px']}>
        <Col>
          <BodyTitle mb="40px" color="grayscale.600">
            Gift Voucher Terms & Conditions
          </BodyTitle>
        </Col>
      </Row>
      <Row borderTop="1px solid" borderColor="grayscale.300" pt="40px">
        <Col width={[1]}>
          <StyledConditions>
            {parseHtml(termsAndConditions.content)}
          </StyledConditions>
        </Col>
      </Row>
    </FormLayout>
  );
};

GiftVoucherFormLayout.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool,
  status: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  giftVoucherData: PropTypes.shape(),
  termsAndConditions: PropTypes.shape({
    content: PropTypes.string,
  }).isRequired,
  siteConfig: PropTypes.shape(),
  userIsLoggedIn: PropTypes.bool.isRequired,
  values: PropTypes.shape(),
};

const GiftVoucherForm = compose(
  withLocation,
  withFormik({
    mapPropsToValues: ({ user, search: { theme } }) => ({
      theme: theme && themeOptions.indexOf(theme) !== -1 ? theme : '',
      email: user?.email ? user.email : '',
      terms: false,
      fromName: '',
      toName: '',
      purchasedFor: 'Someone',
      hasDiscount: true, // giftVoucherData.hasDiscount,
    }),
    validationSchema: () =>
      Yup.object().shape({
        price: Yup.number().required('Select a price'),
        email: Yup.string().email().required('Enter your email'),
        terms: Yup.boolean()
          .oneOf([true], 'Must Accept Terms and Conditions')
          .required(),
      }),
    handleSubmit: async (
      values,
      {
        setSubmitting,
        setStatus,
        props: {
          giftVoucherData: { hasDiscount, discount },
          createGiftVoucher,
          stripe,
          elements,
          path,
        },
      },
    ) => {
      if (!stripe || !elements) {
        // Stripe.js has not loaded yet. Make sure to disable
        // form submission until Stripe.js has loaded.
        return;
      }
      try {
        const formData = values;
        // Get a reference to a mounted CardElement. Elements knows how
        // to find your CardElement because there can only ever be one of
        // each type of element.
        const cardElement = elements.getElement(CardElement);

        if (!hasPermission(['FREE_GIFT_VOUCHERS'])) {
          // Use your card Element with other Stripe.js APIs
          const { token, error } = await stripe.createToken(cardElement, {
            name: formData.email,
          });
          if (error || !token) {
            setStatus({ message: error.message, type: 'danger' });
            setSubmitting(false);
            return;
          }
          formData.stripeToken = `${token.id}`;
        }
        const slug = path.replace('gift-vouchers/', '') || 'purchase';

        const { data, error: voucherError } = await createGiftVoucher({
          variables: {
            ...formData,
            saveCard: false,
            value: formData.price,
            price: !hasDiscount
              ? formData.price
              : formData.price * (1 - discount),
            siteId: parseInt(process.env.GATSBY_SITE_ID, 10),
            slug,
          },
        });

        if (voucherError) {
          setStatus({ message: voucherError.message, type: 'danger' });
          setSubmitting(false);
          return;
        }

        if (data.createGiftVoucher.id) {
          setSubmitting(false);
          await navigate(
            `/gift-vouchers/${data.createGiftVoucher.id}/complete/`,
          );
        }
      } catch (e) {
        setStatus({
          message: errorMessage(e, 'There was an error'),
          type: 'danger',
        });
        setSubmitting(false);
      }
    },
  }),
)(GiftVoucherFormLayout);

GiftVoucherForm.propTypes = {
  giftVoucherData: PropTypes.shape(),
  createGiftVoucher: PropTypes.func.isRequired,
  stripe: PropTypes.object,
};

const DataWrappedGiftVoucher = (props) => {
  const stripe = useStripe();
  const elements = useElements();

  const { giftVoucherPage } = useStaticQuery(graphql`
    query giftVoucherData {
      giftVoucherPage {
        discount
        giftVoucherPrices
        foreignId
        slug
        title
        content
        parentId
        id
        hasDiscount
        content
      }
    }
  `);
  return (
    <GiftVoucherForm
      giftVoucherData={giftVoucherPage}
      stripe={stripe}
      elements={elements}
      {...props}
    />
  );
};

export default compose(
  apolloGraphql(CREATE_GIFT_VOUCHER, { name: 'createGiftVoucher' }),
)(DataWrappedGiftVoucher);
