import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Router } from '@reach/router';
import { graphql } from 'gatsby';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import {
  Button,
  BodyTitle,
  Text,
  Box,
  Row,
  Col,
  Title,
  Divider,
  Icon,
} from '@firsttable/web-components/atoms';
import ContainerContent from '@firsttable/web-components/molecules/ContainerContent';
import RowHead from '@firsttable/web-components/molecules/RowHead';
import ContactCTA from '@firsttable/web-components/molecules/ContactCTA';
import { getUser, isBrowser, refreshUser } from '@firsttable/functions';
import { graphql as apolloGraphql, withApollo } from '@apollo/client/react/hoc';
import { flowRight as compose } from 'lodash';
import { gql } from '@apollo/client';
import GiftVoucherForm from '../components/organisms/Forms/GiftVoucherForm';
import GiftVoucherRedeemForm from '../components/organisms/Forms/GiftVoucherRedeemForm';
import PrivateRoute from '../components/PrivateRoute';
import ConfirmationLayout from '../layouts/ConfirmationLayout';
import SEO from '../components/seo';
import usePageLoading from '../hooks/pageLoading';
import LoadingLayout from '../layouts/LoadingLayout';
import TwoColumnLayout from '../layouts/TwoColumnLayout';
import GenericLayout from '../layouts/GenericLayout';
import MissingRoute from '../layouts/MissingRoute';
import ellipse from '../images/ellipse-01.svg';
import { isGlobalSite } from '../helpers/data';
import GlobalLayout from '../layouts/GlobalLayout';
import NavButton from '../components/atoms/NavButton';
import AuthContext from '../context/AuthContext';
import parseHtml from '../helpers/parseHtml';
import imgFork from '../components/organisms/ContactUs/fork.svg';

const stripePromise = loadStripe(process.env.GATSBY_STRIPE_PUBLIC_KEY);

const MainPage = () => (
  <GenericLayout>
    <ContainerContent
      mt={[null, null, '-120px']}
      mb={['50px', '50px', '0px']}
      background={[null, null, `url(${ellipse}) no-repeat 0 50%`]}
      pt={[null, null, '175px']}
      pb={[null, null, '175px']}
    >
      <RowHead title="Gift Vouchers" />
      <Row gap={0} mt={['0px', '20px', '70px']}>
        <Col
          textAlign={['center', 'center', 'left']}
          width={[1, 1, 1 / 2]}
          mb={['20px', '20px', 0]}
        >
          <Box position="relative" pr={[null, null, '72px']}>
            <Title m={['0 0 20px', '0 0 20px', '0 0 50px']}>
              Buy a Gift Voucher
            </Title>
            <Text fontSize="xl" mb={0}>
              Here&rsquo;s something everyone will love! Purchase a First Table
              gift voucher and treat your nearest and dearest to First Table
              booking credit to use on any occasion.
            </Text>
            <Box
              maxWidth="208px"
              mx="auto"
              mt="20px"
              display={['block', 'block', 'none']}
            >
              <Button
                NavComponent={NavButton}
                to="/gift-vouchers/purchase/"
                kind="cta"
                size="l"
                wide
              >
                Buy a gift voucher
              </Button>
            </Box>
          </Box>
        </Col>
        <Col
          textAlign={['center', 'center', 'left']}
          width={[1, 1, 1 / 2]}
          mb={['20px', '20px', 0]}
        >
          <Divider display={['block', 'block', 'none']} m={['50px 0']} />
          <Box
            position="relative"
            pl={[null, null, '72px']}
            borderLeft={[null, null, '1px solid']}
            borderColor={[null, null, 'gold.800']}
          >
            <Box
              position="absolute"
              top="-45px"
              left="-10px"
              display={['none', 'none', 'block']}
            >
              <Icon
                width="28px"
                name="cutlery"
                color="gold.800"
                viewBox="0 0 40 48"
              />
            </Box>
            <Title m={['0 0 20px', '0 0 20px', '0 0 50px']}>
              Redeem a Gift Voucher
            </Title>
            <Text fontSize="xl" mb={0}>
              Are you the lucky recipient of a First Table Gift Voucher? Click
              here to redeem it.
            </Text>
          </Box>
        </Col>
      </Row>
      <Row>
        <Col width={[1 / 2]} display={['none', 'none', 'block']}>
          <Box maxWidth="208px" mx="auto" mt="20px">
            <Button
              NavComponent={NavButton}
              to="/gift-vouchers/purchase/"
              kind="cta"
              size="l"
              wide
            >
              Buy a gift voucher
            </Button>
          </Box>
        </Col>
        <Col width={[1, 1, 1 / 2]}>
          <Box maxWidth="208px" mx="auto" mt="20px">
            <Button
              NavComponent={NavButton}
              to="/gift-vouchers/redeem/"
              kind="cta"
              size="l"
              wide
            >
              Redeem a voucher
            </Button>
          </Box>
        </Col>
      </Row>
    </ContainerContent>
  </GenericLayout>
);

const GiftVoucherRedeem = () => (
  <TwoColumnLayout
    title="Gift Vouchers"
    col1={
      <>
        <BodyTitle>Redeem your First Table gift voucher</BodyTitle>
        <Text>
          Simply enter the voucher code from your First Table Gift Voucher
          below. The value of the voucher will be added as credit to your First
          Table login.
        </Text>
        <GiftVoucherRedeemForm />
      </>
    }
    col2={<ContactCTA NavButtonComponent={NavButton} />}
  />
);

// see here - https://stackoverflow.com/questions/40987309/react-display-loading-screen-while-dom-is-rendering
// need to wrap any loading layout in an element like span for SSR
// so the loaded elem isn't bound to it
// not sure if this is a bug or not
const GiftVoucherRoute = () => {
  const loading = usePageLoading();
  return (
    <>
      {loading ? (
        <span>
          <LoadingLayout showFooter showMenu />
        </span>
      ) : (
        <MainPage />
      )}
    </>
  );
};

GiftVoucherRoute.propTypes = {};
const GiftVoucherPurchase = (props) => {
  const user = getUser();
  const { isLoggedIn } = useContext(AuthContext);
  const userIsLoggedIn = isLoggedIn();
  const { giftVoucherPageData } = props;
  return (
    <Elements stripe={isBrowser ? stripePromise : null}>
      <GenericLayout>
        <ContainerContent>
          <RowHead>
            <Col width={3 / 4} mx="auto" line my={20} icon={imgFork}>
              {giftVoucherPageData.content ? (
                <Box width={1} mx="auto" textAlign="center">
                  {parseHtml(giftVoucherPageData.content)}
                </Box>
              ) : (
                <>
                  <Title as="h1" mb="s">
                    Buy a Gift Voucher
                  </Title>
                  <Text letterSpacing="-0.5px" fontSize="l">
                    Gift vouchers are redeemable for credit on First Table and
                    do not expire.
                  </Text>
                </>
              )}
            </Col>
          </RowHead>
          <GiftVoucherForm
            user={user}
            userIsLoggedIn={userIsLoggedIn}
            {...props}
          />
        </ContainerContent>
      </GenericLayout>
    </Elements>
  );
};
GiftVoucherPurchase.propTypes = {
  giftVoucherPageData: PropTypes.shape(),
};

const GiftVoucher = ({
  data: { giftVoucherPage, siteConfig, termsAndConditions },
  userToken,
  client,
}) => {
  const { logOut, isLoggedIn } = useContext(AuthContext);
  const isUserLoggedIn = isLoggedIn();

  useEffect(() => {
    // check for valid user token, otherwise handle refresh here.
    // this is only temporary logic till we have a better auth solution in Next
    if (userToken?.valid === false && isUserLoggedIn) {
      refreshUser(client)
        .then((d) => {
          if (!d.token) {
            logOut({
              pathname: '/gift-vouchers/purchase/',
              authError: true,
            });
          }
        })
        .catch(() => {
          logOut({
            pathname: '/gift-vouchers/purchase/',
            authError: true,
          });
        });
    }
  }, [userToken]);
  if (!giftVoucherPage) {
    return <SEO meta={[{ 'http-equiv': 'refresh', content: '0;url=/' }]} />;
  }
  if (isGlobalSite) {
    return <GlobalLayout showGiftVoucherLinks />;
  }
  return (
    <>
      <Router>
        <GiftVoucherRoute path="gift-vouchers" />
        <GiftVoucherPurchase
          path="gift-vouchers/purchase"
          siteConfig={siteConfig}
          termsAndConditions={termsAndConditions}
          giftVoucherPageData={giftVoucherPage}
        />
        <PrivateRoute
          path="gift-vouchers/redeem"
          component={GiftVoucherRedeem}
        />
        <ConfirmationLayout path="gift-vouchers/:confirmationId/complete" />
        <MissingRoute default />
      </Router>
    </>
  );
};

GiftVoucher.propTypes = {
  data: PropTypes.shape(),
  userToken: PropTypes.shape(),
  client: PropTypes.any,
};

export default compose(
  withApollo,
  apolloGraphql(
    gql`
      query tokenValidation {
        userToken: validateToken {
          valid
          status
          code
          message
        }
      }
    `,
    {
      // skip: ({ newUserSignUp }) => !newUserSignUp,
      props: ({ data: { userToken } }) => ({
        userToken,
      }),
      options: () => ({
        fetchPolicy: 'network-only',
        ssr: false,
      }),
    },
  ),
)(GiftVoucher);

export const query = graphql`
  query giftVoucherPageData {
    giftVoucherPage {
      ogImage
      foreignId
      metaTitleFormatted
      metaDescription
      metaTags
      content
    }
    siteConfig {
      currencySymbol
      currency
      country
      freeCreditAmount
    }
    termsAndConditions: setting(key: { eq: "GiftVoucherTermsAndConditions" }) {
      id
      key
      content
      title
    }
  }
`;
